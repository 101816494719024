(() => {
    'use strict';

    angular
        .module('App.Survey')
        .component('questionStatisticPopup', {
            template: require('./QuestionStatisticPopupComponent.html'),
            controllerAs: 'ctrl',
            controller: ['SurveyService', QuestionStatisticPopupController],
            bindings: {
                surveyToken: '<',
                departmentToken: '<',
                popup: '=?'
            }
        })

    function QuestionStatisticPopupController(SurveyService) {
        const ctrl = this;

        ctrl.getRatingClass = SurveyService.getRatingClass;

        ctrl.onOpen = onOpen;
        ctrl.onClose = onClose;

        function onOpen(question) {
            ctrl.question = question;

            SurveyService
                .getQuestionInsights(ctrl.surveyToken, ctrl.departmentToken, question.SurveyQuestionId)
                .then(resp => {
                    ctrl.questionScoresPerUserGroup = resp.QuestionScoresPerUserGroup;
                    ctrl.isLoaded = true;
                });
        }

        function onClose(data, popup) {
            delete ctrl.question;
            delete ctrl.questionScoresPerUserGroup;
            delete ctrl.isLoaded;

            popup.hide();
        }
    }
})();