(function () {
    'use strict';

    angular.module('App')
        .component('surveyList', {
            template: require('./SurveyListComponent.html'),
            controllerAs: 'ctrl',
            controller: ['Page', '$timeout', 'HeaderButtonsFactory', 'ResponsiveService', SurveyListComponentController],
            bindings: {
                surveys: '<',
                moduleToken: '<'
            }
        });

    function SurveyListComponentController(Page, $timeout, HeaderButtonsFactory, ResponsiveService) {
        var ctrl = this;

        ctrl.$onInit = init;
        ctrl.openSurvey = openSurvey;
        ctrl.$onDestroy = destroy;

        ctrl.isDesktop = ResponsiveService.isDesktop();

        function init() {
            $timeout(() => {
                ctrl.isLoaded = true;
            }, 250);
        }

        function openSurvey(survey) {
            Page.stateGo('survey', ctrl.moduleToken, survey.SurveyToken, {
                state: 'surveyList',
                extra: survey.SurveyToken,
                token: ctrl.moduleToken
            });
        }

        function destroy() {
            if (ctrl.isDesktop) {
                HeaderButtonsFactory.resetButtonsList()
            }
        }
    }
})();
