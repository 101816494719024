(() => {
    angular
        .module('App.Survey')
        .component('surveyManagerTab', {
            template: require('./SurveyManagerTabComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', '$element', '$timeout', 'Page', 'SurveyService', surveyManagerTabController],
            bindings: {
                surveyToken: '<',
                departmentToken: '<',
                managerViewSettings: '<',
                isSingleDepartment: '<',
                isVisible: '<',
                surveyData: '<'
            }
        })

    function surveyManagerTabController($scope, $element, $timeout, Page, SurveyService) {
        const ctrl = this;

        $scope.$watch('ctrl.isVisible', init);

        function init(isVisible) {
            toggleTabClass(isVisible);

            if (!isVisible)
                return

            !ctrl.isLoaded && SurveyService.getManagerTabData(ctrl.surveyToken, ctrl.departmentToken)
                .then(data => {
                    ctrl.isDepartmentResult = data.IsDepartmentResult;
                    ctrl.participationRate = data.ParticipationRate;

                    ctrl.overallResultValueString = data.OverallResultValueString ?? 'N/A';
                    ctrl.overallResult = data.OverallResult;

                    ctrl.ratingClass = SurveyService.getRatingClass(data.RateType);
                    ctrl.ratingIcon = SurveyService.getRatingIcon(data.RateType);

                    ctrl.departmentInsights = data.DepartmentInsights;
                    ctrl.departmentCount = data.DepartmentCount;
                    
                    ctrl.questionInsights = data.QuestionInsights;

                    if (ctrl.managerViewSettings.MonitorDataCollectionAccess) {
                        $timeout(() => initPartitionRateChart());
                    }
                })
                .finally(() => {
                    ctrl.isLoaded = true;
                });
        }

        function initPartitionRateChart() {
            SurveyService.initParticipationRateChart($element.find('.chart-container')[0], ctrl.participationRate)
        }

        function toggleTabClass(isVisible) {
            document.querySelector('.base-view').classList.toggle('base-view-survey-manager-tab', isVisible);
        }
    }
})();