(function () {
    'use strict';

    angular
        .module('App.Basics')
        .constant('FIELD_TYPE', {
            TEXT: 1,
            MULTILINETEXT: 2,
            DATE: 3,
            DATALIST: 4,
            MEDIA: 5,
            NUMBER: 6,
            INFORMATION: 7,
            REFERENCE: 8,
            DATETIME: 9
        })
        .constant('DISPLAY_TYPE', {
            DEFAULT: 1,
            SELECT: 2,
            BUTTON_GROUP: 3,
            BUTTON_SERIES: 5,
            CHECK_LIST: 4
        })
        .constant('DATALIST_RULE', {
            BLOCK: 4,
            BLOCK_AND_POPUP: 5,
        })
        .constant('DATALIST_TYPE', {
            DEFAULT: 1,
            DATEINTERVAL: 2,
            CUSTOMER: 3,
            USER_GROUP: 4,
            USER: 5,
            DEPARTMENT: 6
        })
        .constant('FILLING_FILTER_TYPE', {
            DATA_LIST: 1,
            ONLY_MY_FILLINGS: 2,
            CREATED_BY_USER_LIST: 3,
            CREATED_BY_DEPARTMENT_LIST: 4,
            CREATED_BY_USER_GROUP_LIST: 5,
            DATE_CREATED: 6,
            ONLY_MY_AREA: 7,
            DRAFTS: 8,
            FILLING_SCORE: 9,
            PERFORMANCE_RANGE: 10,
            STAGES: 11,
            FEEDBACK_STATUS: 13,
            FEEDBACK_RATING: 14,
            NEW_REPORTS: 15,
            CHOOSE_STATUS: 16
        })
        .constant('MODULE_ID', {
            LOGIN: 56,
            WORK_MOOD: 57,
            SURVEY: 16,
            CONTENT: 9,
            CONTENT_SOCIAL_SHARE_FACEBOOK: 117,
            CONTENT_SOCIAL_SHARE_LINKEDIN: 118,
            CONTENT_COMMENT: 143,
            FORM: 60,
            CHART: 24,
            HIGHSCORE: 27,
            SOCIAL: 29,
            SOCIAL_POST: 52,
            SOCIAL_POST_COMMENT: 87,
            SOCIAL_POST_LIKE: 88,
            SOCIAL_HASHTAGS: 111,
            SERVICE_FORM: 93,
            IFRAME: 99,
            DUTYPLAN: 53,
            MEMBERCARD: 58,
            CALENDAR: 97,
            WALKTHROUGH: 79,
            POLICY: 59,
            TRAINING: 77,
            TRAINING_MODULE: 124,
            TRAINING_COURSE_LESSON: 92,
            LOGOUT: 21,
            TASK_MANAGEMENT: 107,
            TASK_MANAGEMENT_COMPLETION: 115,
            CATALOG_ITEM: 109,
            CATALOG_CATEGORY: 126
        })
        .constant('MODULE_STATE', {
            97: 'calendar',
            53: 'dutyplan',
            58: 'memberCard',
            77: 'training',
            79: 'walktrough',
            27: 'highscore'
        })
        .constant('DATA_LIST_SORT_TYPE', {
            SORT_OREDER: 1,
            NAME: 2,
            DISTANCE: 3
        })
        .constant('REACTION_TYPE_ID', {
            LIKE: 1,
            LOVE: 2,
            HAHA: 3,
            WOW: 4,
            SAD: 5,
            ANGRY: 6
        })
        .constant('QUAGGA_BARCODE_TYPE', {
            'code_128_reader': 'CODE128',
            'ean_reader': 'EAN13',
            'ean_8_reader': 'EAN8',
            'code_39_reader': 'CODE39',
            'code_39_vin_reader': 'CODE39',
            'codabar_reader': 'codabar',
            'upc_reader': 'UPC',
            'upc_e_reader': 'UPC',
            'i2of5_reader': 'ITF14',
        })
        .constant('FILE_TYPE', {
            'unknown': 'unknown',

            // images
            'image/png': 'image',
            'image/jpg': 'image',
            'image/jpeg': 'image',
            'image/gif': 'image',
            'image/svg+xml': 'image',

            //file-image
            'image/tiff': 'file-image',
            'application/eps': 'file-image',
            'application/postscript': 'file-image',
            'image/vnd.adobe.photoshop': 'file-image',


            // video
            'video/mp4': 'video',
            'video/avi': 'video',
            'video/3gp': 'video',
            'video/3gpp': 'video',
            'video/mov': 'video',
            'video/mpeg': 'video',
            'video/msvideo': 'video',
            'video/ms-wmv': 'video',
            'video/quicktime': 'video',
            'video/webm': 'video',
            'video/x-flv': 'video',
            'video/x-matroska': 'video',
            'video/x-mk': 'video',
            'video/x-msvideo': 'video',
            'video/x-ms-wmv': 'video',

            // audio
            'audio/mp3': 'audio',

            // Word
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'doc',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.template': 'doc',
            'application/msword': 'doc',

            // Excel
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'excel',
            'application/vnd.ms-excel': 'excel',
            'application/vnd.ms-excel.sheet.macroEnabled.12': 'excel',
            'application/vnd.ms-excel.sheet.macroenabled.12': 'excel',
            'application/vnd-xls': 'excel',

            // PDF
            'application/pdf': 'pdf',

            // Powerpoint
            'application/vnd.ms-powerpoint': 'powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'powerpoint',

            //ZIP
            'application/x-zip-compressed': 'zip',
            'application/zip': 'zip',
        })
        .constant('ISSUE_STATUS', {
            IN_PROGRESS: 1,
            DUE_SOON: 2,
            COMPLETE: 3,
            OVERDUE: 4
        })
        .constant('MEDIA_TYPE', {
            INITIALIZED: 0,
            IMAGE: 1,
            VIDEO: 2,
            PDF: 3,
            FOLDER: 4,
            UNKNOWN: 5,
            AUDIO: 6,
            EXCEL: 7,
            CSV: 8,
            ZIP: 9,
            WORD: 10,
            POWERPOINT: 11
        })
        .constant('CALENDAR_EVENT_STATUS', {
            ATTENDING: '1',
            FEW_SPOTS_LEFT: '2',
            FULLY_BOOKED: '3',
            CANCELLED: '4',
            COMPLETED: '5'
        })
        .constant('GLOBAL_SEARCH_ITEM_LABEL', {
            'Content.Post': 'SEARCH.CONTENT_POST',
            'Content.Library': 'SEARCH.LIBRARY_POST',
            'Catalog.Item': 'SEARCH.CATALOG_POST',
            'Catalog.Category': 'SEARCH.CATALOG_POST',
            'Social.Post': 'SEARCH.SOCIAL_POST',
        })
        .constant('GLOBAL_SEARCH_MODULE_LABEL', {
            'Content': 'SEARCH.CONTENT_MODULE',
            'Library': 'SEARCH.LIBRARY_MODULE',
            'Catalog': 'SEARCH.CATALOG_MODULE',
            'Social': 'SEARCH.SOCIAL_MODULE',
        })     
        .constant('MESSAGE_STATUS', {
            EDITED: 'CHAT.STATUS.EDITED',
            DELIVERED: 'CHAT.STATUS.DELIVERED',
            SENDING: 'CHAT.STATUS.SENDING',
            FAILED: 'CHAT.STATUS.FAILED',
            POSTPONED: 'CHAT.STATUS.POSTPONED'
        })
})();