(function () {
    'use strict';

    angular
        .module('App.Social')
        .controller('SocialController', ['$scope', '$state', '$filter', '$stateParams', 'socialSettings', 'events',
            'SocialService', 'Page', 'Menu', 'SocialGroupService', SocialController]);

    function SocialController($scope, $state, $filter, $stateParams, socialSettings, events, SocialService, Page, Menu, SocialGroupService) {
        const navigationBadge = socialSettings.NavigationBadge;

        // Set initial values
        $scope.postsData = socialSettings;

        if ($stateParams.data && $stateParams.data.socialPostToken) {
            $scope.postsData.socialPostToken = $stateParams.data.socialPostToken;
            $scope.postsData.limit = $stateParams.data.limit;
        }
        $scope.openCreatePopup = $stateParams.data.openCreatePopup;

        // Apply data to settings
        $scope.postsData.Settings.IsSharedView = socialSettings.IsSharedView;
        $scope.postsData.Settings.AccountModuleToken = socialSettings.AccountModuleToken;
        $scope.postsData.Settings.SocialGroupId = $state.params.extra;

        //manually go to all if Reset Social Group enabled
        if (socialSettings.SelectedSocialGroupId && $state.params.data?.openGroup && $state.params.extra === '') {
            socialSettings.PageSubTitle = '';
            socialSettings.SelectedSocialGroupId = null;
        }

        if ($scope.postsData.Settings.IsSharedView) {
            $scope.postsData.Settings.SocialGroupName = socialSettings.PageSubTitle;
        }

        if (!$scope.postsData.IsGroupViewEnabled || $scope.postsData.SelectedSocialGroupId) {
            SocialGroupService.removeSelectedSocialGroup(socialSettings.AccountModuleToken);
        }

        if (!socialSettings.PageSubTitle?.length && $scope.postsData.Settings.IsSharedView) {
            const allLabel = $filter('translate')('SOCIAL.ALL');
            Page.setTitle(socialSettings.PageTitle, allLabel);
        } else {
            Page.setTitle(socialSettings.PageTitle, socialSettings.PageSubTitle);
        }

        Page.trackPageView();

        if ($stateParams.backState != null) {
            Page.showBackButton($stateParams.backState.state, $stateParams.backState.token, $stateParams.backState.extra);
        } else {
            Page.showBackButton('home');
        }

        let badgesCount = 0;
        let socialExtra =  $state.params.extra;
        if (socialSettings.IsGroupViewEnabled) {
            const openedSocialGroup = socialSettings.SocialGroups.find(group => group.SocialGroupId === socialSettings.SelectedSocialGroupId);
            if (openedSocialGroup) {
                badgesCount = socialSettings.SocialGroups[0].BadgeCount - openedSocialGroup.BadgeCount;
                socialExtra = null;
            }
        }

        // Update menu activity
        const badgeCount = Menu.updateActivity(badgesCount, $state.current.name, $state.params.token, socialExtra);
        Menu.updateLandingBadge(badgesCount, navigationBadge.AccountModuleId, navigationBadge.ModuleId, navigationBadge.ModuleItemId, badgeCount);

        SocialService.setDecreaseBadgeCountFunction(count => {
            Menu.removeActivity(count, $state.current.name, $state.params.token, $state.params.extra);
            Menu.removeLandingBadge(count, navigationBadge.AccountModuleId, navigationBadge.ModuleId, navigationBadge.ModuleItemId);
        });

        // Listen for events
        $scope.$on(events.DEPARTMENT_CHANGED, function () {
            if ($stateParams.extra) {
                Page.stateGoBack('social', socialSettings.AccountModuleToken, null, $stateParams.backState);
            } else {
                Page.stateReload();
            }
        });
    }
})();